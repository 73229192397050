<template>
  <div class="position-relative">
    <div class="bg-light">
      <div class="container py-5">
        <div class="row h-100 align-items-center py-5">
          <div class="col-12 col-lg-12 col-md-12 mt-5">
            <h1 class="display-4 font-weight-bold text-white mt-5">Services</h1>
            <p class="lead text-white mb-5">
              convenience in every delivery service.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5">
      <div class="container">
        <div class="row pb-5">
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div
              class="card mt-3 shadow"
              style="cursor: pointer"
              @click.prevent="toSubmitPackages()"
            >
              <div class="card-body">
                <img
                  class="card-img-left example-card-img-responsive"
                  src="@/assets/images/air-freight.png"
                  width="100"
                  height="100"
                />
                <h4 class="f-bold cl-primary">Air Freight</h4>
                <p class="card-text cl-secondary mt-4">
                  Goods delivery services via airplane in the air with units
                  per-KG. This line is a fast delivery solution for small or
                  large quantity needs
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div
              class="card mt-3 shadow"
              @click.prevent="toSubmitPackages()"
              style="cursor: pointer"
            >
              <div class="card-body">
                <img
                  class="card-img-left example-card-img-responsive"
                  src="@/assets/images/sea-freight.png"
                  width="100"
                  height="100"
                />
                <h4 class="f-bold cl-primary">Sea Freight</h4>
                <p class="card-text cl-secondary mt-4">
                  Shipping services by sea using containers with unit per
                  cubication. We accept FCL & LCL. this line is a solution for
                  bulk shipments, witch can save shipping costs
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-5">
      <div class="container">
        <div class="bg-light-no p-5">
          <div class="row">
            <div class="col-12 col-lg-9 col-md-9 col-sm-12">
              <h4 class="font-weight-bold text-white">
                Are you interested to work with us?
              </h4>
            </div>
            <div class="col-12 col-lg-3 col-md-3 col-sm-3">
              <router-link to="/contacts" class="btn btn-warning px-4">
                Contact Us</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
export default {
  name: "Services",
  data() {
    return {
      loading: true,
      data: [],
      token: localStorage.getItem("token"),
      role: localStorage.getItem("role"),
    };
  },
  methods: {
    toSubmitPackages() {
      if (this.data.token !== "" || this.data.token !== null) {
        this.$router.push("/customers/submit-packages/");
      } else {
        Vue.swal(
          "Unauthorized!",
          "Please login first before submit packages!",
          "error"
        );
      }
    },
  },
};
</script>
